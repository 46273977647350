export const preloadImages = srcArray => {
  const promises = srcArray.map(src => {
    return new Promise(resolve => {
      const img = new Image();
      img.src = src;
      img.onload = resolve;
      img.onerror = resolve;
    });
  });
  return Promise.all(promises);
};
